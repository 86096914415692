import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/deals",
    name: "DealsApproval",
    component: () => import("@/modules/deals/views/TheDealsApproval.vue"),
    beforeEnter: AuthGuard.withToken,
    meta: {
      title: "deals.meta",
      layout: "AuthenticatedLayout",
    },
  },
  {
    path: "/deals/:dealId",
    name: "DealById",
    component: () => import("@/modules/deals/views/TheDealsApprovalOverview.vue"),
    beforeEnter: AuthGuard.withToken,
    meta: {
      title: "deals.meta",
      layout: "AuthenticatedLayout",
    },
  },
  {
    path: "/deals/late/",
    name: "DealsLate",
    component: () => import("@/modules/deals/views/TheDealsLate.vue"),
    beforeEnter: AuthGuard.withToken,
    meta: {
      title: "deals.meta",
      layout: "AuthenticatedLayout",
    },
  },
  {
    path: "/deals/late/:dealId",
    name: "DealLateById",
    component: () => import("@/modules/deals/views/TheDealLateOverview.vue"),
    beforeEnter: AuthGuard.withToken,
    meta: {
      title: "deals.meta",
      layout: "AuthenticatedLayout",
    },
  },
];
