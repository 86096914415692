import { createRouter, createWebHistory, type RouteRecordRaw } from "vue-router";
import AccountRoutes from "@/modules/account/router";
import CreatorsRoutes from "@/modules/creators/router";
import CompaniesRoutes from "@/modules/companies/router";
import AdministratorsRoutes from "@/modules/administrators/router";
import ErrorRoutes from "@/modules/error/router";
import CampaignsApprovalRoutes from "@/modules/approval/campaigns/router";
import CreatorApprovalRoutes from "@/modules/approval/creators/router";
import CertificationsRoutes from "@/modules/approval/certifications/router";
import SubCertificationsRoutes from "@/modules/approval/subcertifications/router";
import ContentRoutes from "@/modules/approval/content/router";
import DealsRoutes from "@/modules/deals/router";
import CampaignsRoutes from "@/modules/campaigns/router";
import PayoutRoutes from "@/modules/payouts/router";

const baseRoutes: RouteRecordRaw[] = [
  {
    path: "/",
    redirect: "/creators",
  },
];

const routes: RouteRecordRaw[] = baseRoutes.concat(
  AccountRoutes,
  CreatorsRoutes,
  CompaniesRoutes,
  AdministratorsRoutes,
  ErrorRoutes,
  CampaignsRoutes,
  CreatorApprovalRoutes,
  CertificationsRoutes,
  SubCertificationsRoutes,
  ContentRoutes,
  DealsRoutes,
  CampaignsApprovalRoutes,
  PayoutRoutes
);

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return {
        ...savedPosition,
        behavior: "smooth",
      };
    }
    if (to.name === from.name && to.hash !== "" && to.hash !== from.hash) {
      return;
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        document.getElementById("test-scroll")?.scroll({
          top: 0,
          behavior: "smooth",
        });

        resolve({
          top: 0,
          behavior: "smooth",
        });
      }, 200);
    });
  },
});

export default router;
