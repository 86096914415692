import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/payouts",
    name: "CreatorPayouts",
    component: () => import("@/modules/payouts/views/CreatorPayouts.vue"),
    beforeEnter: AuthGuard.withToken,
    meta: { title: "payouts.meta", layout: "AuthenticatedLayout" },
  },
  {
    path: "/payout/:id",
    name: "PayoutOverview",
    component: () => import("@/modules/payouts/views/PayoutOverview.vue"),
    beforeEnter: AuthGuard.withToken,
    meta: { title: "payouts.meta", layout: "AuthenticatedLayout" },
  },
];
