import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/creators",
    name: "Creators",
    component: () => import("@/modules/creators/views/TheCreators.vue"),
    beforeEnter: AuthGuard.withToken,
    meta: { title: "creators.meta", layout: "AuthenticatedLayout" },
  },
  {
    path: "/creators/creator/:id",
    name: "CompanyOverview",
    component: () => import("@/modules/creators/creator/views/CreatorOverview.vue"),
    beforeEnter: AuthGuard.withToken,
    meta: { layout: "AuthenticatedLayout" },
  },
];
