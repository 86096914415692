import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/approval/creators",
    name: "CreatorsApproval",
    component: () => import("@/modules/approval/creators/views/TheCreatorsApproval.vue"),
    beforeEnter: AuthGuard.withToken,
    meta: { title: "campaigns.meta", layout: "AuthenticatedLayout" },
  },
  {
    path: "/approval/creator/:creatorId",
    name: "CreatorById",
    component: () => import("@/modules/approval/creators/views/TheCreatorApprovalOverview.vue"),
    beforeEnter: AuthGuard.withToken,
    meta: { title: "campaigns.meta", layout: "AuthenticatedLayout" },
  },
];
