import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/companies",
    name: "Companies",
    component: () => import("@/modules/companies/views/TheCompanies.vue"),
    beforeEnter: AuthGuard.withToken,
    meta: { title: "companies.meta", layout: "AuthenticatedLayout" },
  },
  {
    path: "/companies/company/:id",
    name: "Company",
    component: () => import("@/modules/companies/company/views/CompanyOverview.vue"),
    beforeEnter: AuthGuard.withToken,
    meta: { title: "companies.meta", layout: "AuthenticatedLayout" },
  },
];
