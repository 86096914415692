import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/campaigns",
    name: "Campaigns",
    component: () => import("@/modules/campaigns/views/TheCampaigns.vue"),
    beforeEnter: AuthGuard.withToken,
    meta: {
      title: "campaigns.meta",
      layout: "AuthenticatedLayout",
    },
  },
  {
    path: "/campaign/:campaignId",
    name: "CampaignOverview",
    component: () => import("@/modules/campaigns/views/TheCampaignOverview.vue"),
    props: true,
    beforeEnter: AuthGuard.withToken,
    meta: { title: "campaigns.meta", layout: "AuthenticatedLayout" },
  },
];
