import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/approval/campaigns",
    name: "CampaignsApproval",
    component: () => import("@/modules/approval/campaigns/views/TheCampaignsApproval.vue"),
    beforeEnter: AuthGuard.withToken,
    meta: { title: "campaigns.meta", layout: "AuthenticatedLayout" },
  },
  {
    path: "/approval/campaign/:campaignId",
    name: "CampaignApprovalOverview",
    component: () => import("@/modules/approval/campaigns/views/TheCampaignApprovalOverview.vue"),
    props: true,
    beforeEnter: AuthGuard.withToken,
    meta: { title: "campaigns.meta", layout: "AuthenticatedLayout" },
  },
];
